import { ChangeDetectorRef, Component, Input, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Constants } from 'src/app/core/services/constants.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { DeliveryInstruction } from 'src/app/shared/model/delivery-instruction/delivery-instruction.dto';
import { DateFormat, DateHelperService } from 'src/app/shared/services/date-helper.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeliveryRequestsModule } from '../../delivery-requests.module';
import { DeliveryRequestCartService } from '../delivery-request-cart.service';
import { CartDto, DeliveryInstructionDto, DeliveryInstructionPackage } from './../../../shared/model/delivery-instruction/delivery-instruction.dto';

@Component({
  standalone: true,
  selector: 'prt-delivery-request-cart-form',
  templateUrl: './delivery-request-cart-form.component.html',
  styleUrls: ['./delivery-request-cart-form.component.scss'],
  imports: [SharedModule, forwardRef(() => DeliveryRequestsModule)],
  providers: [ConfirmationService]
})
export class DeliveryRequestCartFormComponent implements OnInit {
  _request!: DeliveryInstruction;
  @Input() set request(r: DeliveryInstruction) {
    this._request = r;
    this.validatePackages();
    this.form.patchValue({
      ...this.emptyForm,
      ...r,
      expectedDeliveryDateFormatted: r.expectedDeliveryDate ? DateHelperService.format(new Date(r.expectedDeliveryDate), DateFormat['DD/MM/YYYY HH:mm']) : ''
    });
  };

  emptyForm = {
    expectedDeliveryDateFormatted: '',
    modeOfTransport: '',
    gacInstruction: '',
    customsInstruction: ''
  };
  form!: UntypedFormGroup;

  modesOfTransport = ['Sea', 'Air', 'Land'];

  Constants = Constants;
  isSaveInProgress = false;
  isSendInProgress = false;
  showValidationErrors$ = new BehaviorSubject<boolean>(false);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public layoutService: LayoutService,
    private cartService: DeliveryRequestCartService,
    private changeDetector: ChangeDetectorRef,
    private toasterService: MessageService,
    private confirmationService: ConfirmationService) {
    this.form = this.formBuilder.group({
      expectedDeliveryDateFormatted: ['', this.validateRequired.bind(this)],
      modeOfTransport: ['', this.validateRequired.bind(this)],
      gacInstruction: '',
      customsInstruction: ''
    }, { validators: [this.validatePackages.bind(this)] });
  }

  ngOnInit() {
    this.showValidationErrors$.subscribe((show) => {
      this.validateForm();
    });
  }

  get expectedDeliveryDateFormattedControl() {
    return this.form.get('expectedDeliveryDateFormatted');
  }

  get modeOfTransportControl() {
    return this.form.get('modeOfTransport');
  }

  onSelectionUpdate(event: any) {
    this.validateForm();
  }

  validateForm() {
    this.form.markAllAsTouched();
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control?.updateValueAndValidity();
    });
  }

  validateRequired(control: AbstractControl): ValidationErrors | null {
    return this.showValidationErrors$.value ? Validators.required(control) : null;
  }

  validatePackages(): ValidationErrors | null {
    if (!this.showValidationErrors$.value) {
      return null;
    }
    let errors: ValidationErrors = {};
    if (!this._request.packages.filter(x => x.isSelected).length) {
      errors.noPackagesSelected = true;
    }
    if (this._request.packages.filter(x => x.isInvalidForCart).length) {
      errors.invalidPackages = true;
    };
    return errors;
  }

  save() {
    this._request.isLoading = true;
    this.isSaveInProgress = true;
    let saveRequest: CartDto = {
      ...this._request,
      packagesToAdd: this._request.packages,
      ...this.form.value,
      expectedDeliveryDate: DateHelperService.stripTimezoneDate(this.form.value.expectedDeliveryDateFormatted) ?? null,
    };

    this.cartService.save(saveRequest)
      .pipe(finalize(() => {
        this._request.isLoading = false;
        this.isSaveInProgress = false;
        this.showValidationErrors$.next(false);
        this.changeDetector.detectChanges();
      }))
      .subscribe(() => {
        this.toasterService.add({ severity: 'success', summary: 'Cart saved successfully', styleClass: 'no-detail' });
      }, () => {
        this.toasterService.add({ severity: 'error', summary: 'Error saving the request', styleClass: 'no-detail' });
      });
  }

  send(event: Event) {
    let confirmed = () => {
      this._request.isLoading = true;
      this.isSendInProgress = true;

      let sendRequest: DeliveryInstructionDto = {
        ...this._request,
        ...this.form.value,
        expectedDeliveryDate: this.form.value.expectedDeliveryDateFormatted ? DateHelperService.stripTimezoneDate(this.form.value.expectedDeliveryDateFormatted) : null,
      };

      this.cartService.send(sendRequest)
        .pipe(finalize(() => {
          this._request.isLoading = false;
          this.isSendInProgress = false;
          this.changeDetector.detectChanges();
        }))
        .subscribe((response) => {
          if (response?.sent?.invalidPackages?.length) {
            this.showErrorAfterSending();
          }
          else {
            this.showValidationErrors$.next(false);
          }
        }, () => {
          this.showErrorAfterSending();
        });
    };

    this.showValidationErrors$.next(true);
    setTimeout(() => {
      if (this.form.invalid) {
        return;
      }
      else {
        this.confirmationService.confirm({
          target: event.target as HTMLElement,
          key: `sendRequest-${this._request?.id}`,
          icon: 'pi pi-question-circle text-primary-400',
          message: 'Request will be sent immediately. \nPlease confirm.',
          acceptLabel: 'Send',
          accept: () => confirmed()
        });
      }
    });
  }

  showErrorAfterSending() {
    this.showValidationErrors$.next(true);
    this.toasterService.add({ severity: 'error', summary: 'Request could not be sent', styleClass: 'no-detail' });
  }

  removePackage(p: DeliveryInstructionPackage) {
    this._request.isLoading = true;
    let index = this._request.packages.findIndex(x => x.packageCode === p.packageCode && x.poNumber === p.poNumber);
    if (index !== -1) this._request.packages.splice(index, 1);

    this.cartService.removePackageFromCart(p.packageCode, p.poNumber, this._request.id ?? '')
      .pipe(finalize(() => {
        this.validatePackages();
        this._request.isLoading = false;
      })).subscribe(() => {
        this.toasterService.add({ severity: 'success', summary: 'Package removed from cart', styleClass: 'no-detail' });
      }, () => {
        this._request.packages.splice(index, 0, p);
        this.toasterService.add({ severity: 'error', summary: 'Error removing the package from cart', styleClass: 'no-detail' });
      });
  }
}
